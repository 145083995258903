import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Store from "./Store";
import Home from "./pages/Home";

export default function Navigation() {

    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Home />} />
                </Routes>
            </BrowserRouter>
        </Store>
    )
}