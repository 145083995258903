import {initializeApp} from "firebase/app";
import {collection, getDocs, getDoc, orderBy, setDoc, doc, getFirestore, serverTimestamp, query, where, limit, startAfter, startAt} from "firebase/firestore";
import {useContext, useEffect, useState} from "react";
import {Context} from "../Store";
import {generateRandomString} from "../libs/utils";

export const getFirebaseConfig = () => {
    return  {
        apiKey: "AIzaSyCYnZZF-p20XaMCZN3uALwv6e6Vv2NJv5s",
        authDomain: "seano-nft.firebaseapp.com",
        projectId: "seano-nft",
        storageBucket: "seano-nft.appspot.com",
        messagingSenderId: "494230645362",
        appId: "1:494230645362:web:4b735aa63269f5da3b30eb",
        measurementId: "G-990V9QNWWD"
    };
}
export function useFirestore() {
    const [state, dispatch] = useContext(Context);
    const [app, setApp] = useState(null);
    const [db, setDb] = useState(null);
    const [dataLists, setDataLists] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const initFirebase = () => {
        const _app = initializeApp(getFirebaseConfig());
        const _db = getFirestore(_app);
        setApp(_app);
        setDb(_db);
    }

    useEffect(() => {
        initFirebase();
    }, [])

    const putData = async (collectionId, docId, data) => {
        const _app = initializeApp(getFirebaseConfig());
        const _db = getFirestore(_app);
        setApp(_app);
        setDb(_db);
        const docRef = doc(_db, collectionId, docId);
        await setDoc(docRef, {
            ...data,
            createdAt: serverTimestamp(),
        });
    }

    const getData = async (
        collectionId,
        perPage= 10,
        currentPage= 1,
        orderColumn="createdAt",
        orderDir="desc",
        lastData=null,
        wherePayload=[]
    ) => {
        return new Promise(async(resolve, reject) => {
            setIsLoading(true);
            try {
                const _app = initializeApp(getFirebaseConfig());
                const _db = getFirestore(_app);
                setApp(_app);
                setDb(_db);
                const colRef = collection(_db, collectionId);
                let qTotal = query(colRef, orderBy(orderColumn, orderDir))
                wherePayload.forEach(clause => {
                    qTotal = query(qTotal, where(...clause));
                });
                const querySnapshotTotal = await getDocs(qTotal);
                const totalData = querySnapshotTotal.size;
                let q;
                if(lastData){
                    q = query(
                        colRef,
                        orderBy(orderColumn, orderDir),
                        startAfter(lastData),
                        limit(perPage*currentPage)
                    );
                    wherePayload.forEach(clause => {
                        q = query(q, where(...clause));
                    });
                } else {
                    q = query(
                        colRef,
                        orderBy(orderColumn, orderDir),
                        limit(perPage*currentPage)
                    );
                    wherePayload.forEach(clause => {
                        q = query(q, where(...clause));
                    });
                }


                const querySnapshot = await getDocs(q);
                let data = [];
                let newLastDoc = null;
                querySnapshot.forEach((doc) => {
                    if (!newLastDoc) {
                        newLastDoc = doc;
                    }
                    data.push(doc.data());
                });
                setIsLoading(false);
                setDataLists(data);
                resolve({
                    data: data,
                    total: totalData,
                    currentPage: currentPage,
                    perPage: perPage,
                    paginateLength: totalData / perPage,
                    lastData: newLastDoc,
                });
            } catch (e) {
                setIsLoading(false);
                reject(e);
            } finally {
                setIsLoading(false);
            }
        })

    }

    const getRow = async (collectionId, wherePayload) => {
        return new Promise(async(resolve, reject) => {
            setIsLoading(true);
            try {
                const _app = initializeApp(getFirebaseConfig());
                const _db = getFirestore(_app);
                setApp(_app);
                setDb(_db);
                const colRef = collection(_db, collectionId);
                let q = query(colRef)
                wherePayload.forEach(clause => {
                    q = query(q, where(...clause));
                });
                const querySnapshot = await getDocs(q);
                let data = [];
                querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                });
                setIsLoading(false);
                setDataLists(data);
                resolve(data);
            } catch (e) {
                setIsLoading(false);
                reject(e);
            } finally {
                setIsLoading(false);
            }
        })
    }

    return {app, db, isLoading, dataLists, putData, getData, getRow}
}