import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {Header, Footer, LoadingWeboo, CopyButton, CopyIcon} from "../components/seanonft-ui";
import Web3 from 'web3';
import axios from "axios";
import {Context} from "../Store";
import {getAppByChainId} from "../libs/Env";
import {NFTStorage, File} from 'nft.storage'
import {useFirestore} from "../hooks/useFirestore";
import {toast} from "react-toastify";
import {Loader, Placeholder} from "rsuite";
import {convertTimestampToDate} from "../libs/utils";
import { FaSync } from 'react-icons/fa';
const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDY3NjgzNzYwRjY5Nzk0RjNlZjgyQkJGZjFBNWUwMWNjNEMyMWUxYkIiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTcwNzQ0OTk3ODk3NywibmFtZSI6InNlYW5vIn0.SuaDCsLwu8CYW9x_OzmSGzfL_3bZ21F7hnN8XiuwWZU'


const NFTRow = ({nft}) => {
    const [state, dispatch] = useContext(Context);
    return (
        <tr key={nft.id} tabIndex="0"
            className="flex items-center justify-between mt-3 border border-gray-200 rounded focus:outline-none">
            <td>
                <div className="flex items-center p-3">
                    {/*<div className="relative flex items-center justify-center flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg">*/}
                    {/*    <img src={nft?.metadata} alt={`NFT ${nft?.address}`}/>*/}
                    {/*</div>*/}
                    <div className="flex items-center pl-10">
                        <div className={"flex flex-col"}>
                            <div
                                className="flex flex-row mr-2 items-start font-medium leading-none text-gray-700">
                                <div className={"mr-2"}><strong>NFT Address:</strong></div>
                                <div>
                                    <a href={getAppByChainId(state.chainId).URL_EXPLORER + "/address/" + nft.address}
                                       className={"underline"} target={"_blank"}>
                                        {nft.address}
                                    </a>
                                </div>
                                <small><CopyIcon text={nft.address}/></small>
                            </div>
                            <div
                                className="flex flex-row mr-2 mt-2 items-start font-medium leading-none text-gray-700">
                                <div className={"mr-2"}><strong>Generator:</strong></div>
                                <div>
                                    <a href={getAppByChainId(state.chainId).URL_EXPLORER + "/address/" + nft.generatorAddress}
                                       className={"underline"} target={"_blank"}>
                                        {nft.generatorAddress}
                                    </a>
                                </div>
                                <small><CopyIcon text={nft.generatorAddress}/></small>
                            </div>
                            <div
                                className="flex flex-row mr-2 mt-2 items-start font-medium leading-none text-gray-700">
                                <div className={"mr-2"}><strong>Chain ID:</strong></div>
                                <div>
                                    {nft.chainId}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </td>
            <td className="pl-5">
                <button
                    className="px-3 py-3 text-sm leading-none text-green-700 bg-green-100 rounded focus:outline-none">
                    # {nft.index}
                </button>
            </td>
        </tr>
    )
}

const NftList = () => {
    const [state, dispatch] = useContext(Context);
    const [isSyncing, setIsSyncing] = useState(true);
    const {app, db, isLoading, dataLists, putData, getData, getRow} = useFirestore();
    const [nfts, setNfts] = useState([]);

    const syncLastSyncIndex = async (lastSyncIndex) => {
        const generatorAbi = getAppByChainId(state.chainId).NFT_GENERATOR_ABI;
        const generatorAddress = getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS;
        const contract = new state.web3.eth.Contract(
            generatorAbi,
            state.web3.utils.toChecksumAddress(generatorAddress)
        );
        const currentContractIndex = await contract.methods.tokenCounter().call();
        console.log("currentIndex", currentContractIndex);
        console.log("lastSyncIndex", lastSyncIndex);
        if (lastSyncIndex >= currentContractIndex) setIsSyncing(false);
        else {
            console.log("Syncing...");
            for (let i = lastSyncIndex; i <= currentContractIndex; i++) {
                setIsSyncing(true);
                contract.methods.pinkNFTs(i).call().then((contractAddress) => {
                    putData('nft_contracts', state.chainId + "-" + contractAddress, {
                        address: contractAddress,
                        chainId: state.chainId,
                        generatorAddress: generatorAddress,
                        index: i
                    }).then((response) => {

                    }).finally(() => {
                        if (i >= currentContractIndex) {
                            putData('generator_contracts', state.chainId + "-" + getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS, {
                                chainId: state.chainId,
                                address: getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS,
                                lastSyncIndex: currentContractIndex
                            }).then((response) => {
                                setIsSyncing(false);
                            })
                        }
                    })
                })
            }
        }

    }
    const syncData = async () => {
        getRow(
            'generator_contracts',
            [
                ['chainId', '==', state.chainId],
                ['address', '==', getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS]
            ]
        ).then((response) => {
            if (!response || response.length === 0) {
                putData('generator_contracts', state.chainId + "-" + getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS, {
                    chainId: state.chainId,
                    address: getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS,
                    lastSyncIndex: 0
                }).then((response) => {
                    syncLastSyncIndex(0);
                })
            } else {
                const lastSyncIndex = response[0]?.lastSyncIndex;
                syncLastSyncIndex(lastSyncIndex);
            }
        })
    }

    const getNftList = async () => {
        getData(
            'nft_contracts',
            10000,
            1,
            'index',
            'desc',
            null,
            [
                ['chainId', '==', state.chainId]
            ]
        ).then((response) => {
            // setNfts(response.data);
            setNfts(response.data.filter((nft) => nft.address !== '0x0000000000000000000000000000000000000000'));
        });
    }

    useEffect(() => {
        if (state.chainId && state.web3) {
            syncData();
            getNftList();
        }
    }, [state])

    return (
        <div className={"mt-5"}>
            <h1 className="mb-3 text-lg font-semibold flex justify-between">
                <div>Generated NFT</div>
                <button type={"button"} onClick={getNftList} className={"text-blue-500 underline ml-3 flex"} disabled={isLoading}>
                    <FaSync className={"w-4 h-4 mr-2"}/>
                </button>
            </h1>
            <div className={"flex flex-row"}>
                <small>Generator Address:</small> &nbsp;
                <a href={getAppByChainId(state.chainId).URL_EXPLORER + "/address/" + getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS}
                   target={"_blank"} className={"underline"}>
                    <small>{getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS}</small>
                </a>
                <CopyIcon text={getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS}/>
            </div>
            {isSyncing && (
                <div className={"flex flex-row align-middle mt-5"}>
                    <div className={"mr-2"}><Loader size="sm" speed="fast"/></div>
                    <div className={"text-gray-500"}><small>Sync Data</small></div>
                </div>
            )}
            {/*{JSON.stringify(nfts)}*/}
            {isLoading && (
                <>
                    <Placeholder.Paragraph style={{marginTop: 30}} rows={4} graph="image" active/>
                    <Placeholder.Paragraph style={{marginTop: 30}} rows={4} graph="image" active/>
                </>
            )}
            {!isLoading && (
                <table className="w-full whitespace-nowrap">
                    <tbody className="flex flex-col gap-4">
                    {nfts.map((nft) => (
                        <NFTRow nft={nft} key={nft.id}/>
                    ))}
                    </tbody>
                </table>
            )}


        </div>
    )
}



const TransactionRow = ({data}) => {
    const [state, dispatch] = useContext(Context);
    const [txStatus, setTxStatus] = useState('Pending');
    const [ipfsStatus, setIpfsStatus] = useState('Pending');
    const checkTxHash = () => {
        if (state.chainId && state.account) {
            state.web3.eth.getTransactionReceipt(data?.txHash).then((receipt) => {
                if (receipt) {
                    setTxStatus('Success');
                }
            })
        }

        // axios.get(`https://api.nft.storage/${data?.metadata?.replace("ipfs://","").replace("/metadata.json","")}`, {
        // axios.get(`https://api.nft.storage/check/bafyreiatey24hv7b7dovgpqilzch5hmdopbb6pse36qgktvazuvlpytlq4`, {
        //     headers: {
        //         Authorization: `Bearer ${NFT_STORAGE_TOKEN}`
        //     }
        // }).then((response) => {
        //     console.log(response);
        // })
    }
    useEffect(() => {
        checkTxHash();
    }, [state.chainId, state.account])
    return (
        <>
            <div className={"border rounded w-full p-2"}>
                <div className={"flex"}>
                    <div className={"w-1/12"}>
                        <img src={data?.image_base64} alt={data?.name} className={"w-16 h-16 rounded"}/>
                    </div>
                    <div className={"w-11/12"}>
                        <div className={"flex flex-col"}>
                            <div className={"flex flex-row w-full"}>
                                <div className={"grid grid-cols-3 gap-4"}>
                                    <div className={"flex flex-row"}>
                                        <div className={"mr-2"}><small
                                            className={"accent-gray-600 font-bold text-gray-500 me-3"}>Name:</small>
                                        </div>
                                        <div><small>{data?.name}</small></div>
                                    </div>
                                    <div className={"flex flex-row"}>
                                        <div className={"mr-2"}><small
                                            className={"accent-gray-600 font-bold text-gray-500 me-3"}>Symbol:</small>
                                        </div>
                                        <div><small>{data?.symbol}</small></div>
                                    </div>
                                    <div className={"flex flex-row"}>
                                        <div className={"mr-2"}><small
                                            className={"accent-gray-600 font-bold text-gray-500 me-3"}>Total
                                            Address:</small>
                                        </div>
                                        <div><small>{data?.addresses?.length}</small></div>
                                    </div>
                                </div>
                            </div>
                            <div className={"flex flex-row w-full"}>
                                <div className={"grid grid-cols-4 gap-4"}>
                                <div className={"flex flex-row"}>
                                        <div className={"mr-2"}><small
                                            className={"accent-gray-600 font-bold text-gray-500 me-3"}>Status:</small>
                                        </div>
                                        <div><small>{txStatus}</small></div>
                                    </div>
                                    <div className={"flex flex-row"}>
                                        <div className={"mr-2"}><small
                                            className={"accent-gray-600 font-bold text-gray-500 me-3"}>Chain ID:</small>
                                        </div>
                                        <div><small>{data?.chainId}</small></div>
                                    </div>
                                    <div className={"flex flex-row"}>
                                        <div className={"mr-2"}><small
                                            className={"accent-gray-600 font-bold text-gray-500 me-3"}>Time:</small>
                                        </div>
                                        <div><small>{convertTimestampToDate(data?.createdAt?.seconds)}</small></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"flex flex-row"}>
                            <div className={"mr-2"}><small
                                className={"accent-gray-600 font-bold text-gray-500 me-3"}>TxHash:</small>
                            </div>
                            <div>
                                <a href={getAppByChainId(state.chainId).URL_EXPLORER + "/tx/" + data?.txHash}
                                   target={"_blank"} className={"underline"}>
                                    <small>{data?.txHash}</small>
                                </a>
                            </div>
                            <div>
                                <CopyIcon text={data?.txHash}/>
                            </div>
                        </div>
                        <div className={"flex flex-row"}>
                            <div className={"mr-2"}><small
                                className={"accent-gray-600 font-bold text-gray-500 me-3"}>Metadata:</small>
                            </div>
                            <div>
                                <a href={"https://ipfs.io/ipfs/" + data?.metadata.replace("ipfs://", '')}
                                   target={"_blank"} className={"underline"}>
                                    <small>{data?.metadata}</small>
                                </a>
                            </div>
                            <div>
                                <CopyIcon text={data?.metadata}/>
                            </div>
                        </div>
                        <div className={"flex flex-row"}>
                            <div className={"mr-2"}><small
                                className={"accent-gray-600 font-bold text-gray-500 me-3"}>Image:</small>
                            </div>
                            <div>
                                <a href={"https://ipfs.io/ipfs/" + data?.metadata.replace("ipfs://", '')}
                                   target={"_blank"} className={"underline"}>
                                    <small>{data?.image}</small>
                                </a>
                            </div>
                            <div>
                                <CopyIcon text={data?.image}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const TransactionList = () => {
    const [state, dispatch] = useContext(Context);
    const {app, db, isLoading, dataLists, putData, getData, getRow} = useFirestore();

    useEffect(() => {
        if (state.chainId && state.account) {
            getData(
                'transactions',
                10000,
                1,
                'createdAt',
                'desc',
                null, [
                    ['chainId', '==', state.chainId],
                ]).then((response) => {
            });
        }
    }, [state])
    return (
        <div className={"mt-5"}>
            <h1 className="mb-3 text-lg font-semibold">Transaction History</h1>
            {/* create list of data using card*/}
            <div className="grid grid-cols-1 gap-4">
                {isLoading && (
                    <>
                        <Placeholder.Paragraph style={{marginTop: 30}} rows={4} graph="image" active/>
                        <Placeholder.Paragraph style={{marginTop: 30}} rows={4} graph="image" active/>
                    </>
                )}
                {!isLoading && (
                    <>
                        {dataLists?.map((data) => {
                            return (
                                <TransactionRow data={data} key={data.id}/>
                            );
                        })}
                    </>
                )}

                {/*{JSON.stringify(dataLists)}*/}
            </div>

        </div>
    )
}

export default function Home() {
    const [state, dispatch] = useContext(Context);
    const [errorMessage, setErrorMessage] = useState('');
    const [imagePreviewUrl, setImagePreviewUrl] = useState('');
    const inputImageRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [isShowForm, setIsShowForm] = useState(false);
    const [image, setImage] = useState(null);
    const [uploading, setUploading] = useState(false);
    const {app, db, isLoading, dataLists, putData, getData, getRow} = useFirestore();
    const [selectedTab, setSelectedTab] = useState('nftList');
    const [key, setKey] = useState(0);
    const [loadingText, setLoadingText] = useState('Loading...');
    const [formData, setFormData] = useState({
        image: null,
        name: '',
        symbol: '',
        maxSupply: '',
        addresses: [''],
    });

    const [nfts, setNfts] = useState([]);

    const handleInputChange = (e, index) => {
        const {name, value, files} = e.target;
        if (name === 'addresses') {
            const newAddresses = [...formData.addresses];
            newAddresses[index] = value;
            setFormData({...formData, addresses: newAddresses});
        } else if (name === 'image') {
            setFormData({...formData, image: files[0]});
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviewUrl(reader.result);
            };
            reader.readAsDataURL(files[0]);
            const file = e.target.files[0];
            setImage(file);
        } else {
            setFormData({...formData, [name]: value});
        }
    };

    const handleTextareaChange = (e) => {
        const {value} = e.target;
        let inputAddresses = [];
        value.replace(/\s+/g, '').match(/.{1,42}/g)?.forEach((address) => {
            if (address.startsWith('0x')) {
                inputAddresses.push(address);
            }
        });

        const newAddresses = [];
        let errorMessageTemp = '';

        inputAddresses.forEach((address) => {
            if (Web3.utils.isAddress(address)) {
                newAddresses.push(address);
            } else {
                errorMessageTemp = `Address '${address}' not valid and can't be input.`;
            }
        });

        setFormData({...formData, addresses: newAddresses});
        setErrorMessage(errorMessageTemp);
    };

    const  getBase64 = async (file) =>{
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => {
                resolve(reader.result)
            }
            reader.onerror = reject
        })
    }



    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingText('Loading...')

        // Validation for image
        if (!formData.image) {
            alert('Image is required');
            return;
        } else if (!formData.image.type.startsWith('image/')) {
            alert('File must be an image');
            return;
        }

        setLoading(true);
        setLoadingText('Uploading Image...');

        const client = new NFTStorage({token: NFT_STORAGE_TOKEN})
        const imageFile = new File([formData.image], `${formData.symbol}.png`, {type: 'image/png'})

        const metadata = await client.store({
            name: formData.name,
            description: formData.symbol,
            image: imageFile
        })
        // // Validation for maxSupply
        // const maxSupplyNumber = Number(formData.maxSupply);
        // if (!maxSupplyNumber || maxSupplyNumber < 0) {
        //     alert('Max Supply must be a positive number');
        //     return;
        // }
        // Logic for send data

        const generatorAbi = getAppByChainId(state.chainId).NFT_GENERATOR_ABI;
        const generatorAddress = getAppByChainId(state.chainId).NFT_GENERATOR_ADDRESS;
        const addresses = formData.addresses.map((address) => state.web3.utils.toChecksumAddress(address));
        const contract = new state.web3.eth.Contract(
            generatorAbi,
            state.web3.utils.toChecksumAddress(generatorAddress)
        );
        setLoadingText('Creating NFT...');
        await contract.methods.createPinkNFT(
            formData.name,
            formData.symbol,
            addresses,
            metadata.url
        ).send({
            from: state.account
        }).on("transactionHash", async (hash) => {
            // setTxHashApprove(hash)
            await getBase64(formData.image).then((base64String) => {
                putData('transactions', hash, {
                    name: formData.name,
                    symbol: formData.symbol,
                    addresses: addresses,
                    metadata: metadata.url,
                    image: metadata.data.image.href,
                    txHash: hash,
                    createdAt: new Date().toISOString(),
                    image_base64: base64String,
                    chainId: state.chainId
                })
            })


            toast.success('Transaction Submitted', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setLoadingText('Waiting for Confirmation...');
        })
            .on("receipt", (receipt) => {
                setLoading(false);
                setKey(key + 1);
            })
            .on("error", (error) => {
                console.log("Error", error);
                toast.error('Transaction Canceled', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setLoading(false);
            });
    };

    const getNftList = async () => {
        axios.get(`https://api-seano.blocksafu.com/api/nft?chain_id=${state.chainId}`).then((response) => {
            const data = response.data;
            // console.log(data);
            setNfts(data?.data);
        })
    }

    useEffect(() => {
        getNftList();
        if (state.chainId && state.account) {
            if (getAppByChainId(state.chainId).ALLOWEDSHOW_FORM.includes(state.account)) {
                setIsShowForm(true);
            }
        } else {
            setIsShowForm(false);
        }
    }, [state])

    return (
        <>
            <Header/>
            <section
                className="relative px-4 py-4 overflow-hidden bg-white md:px-12 lg:px-28 dark:bg-grayc-950 md:py-20 lg:py-20 font-body">

                {isShowForm && (
                    <div className="w-full p-6 text-black bg-gray-100 dark:bg-grayc-900 dark:text-white rounded-2xl">
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div className="flex items-start gap-6">
                                {imagePreviewUrl && (
                                    <div>
                                        <label htmlFor="preview"
                                               className="block text-sm font-medium text-gray-700 dark:text-gray-200">Preview
                                            Image</label>
                                        <img src={imagePreviewUrl} alt="Preview"
                                             className="w-full max-w-xs mt-4 rounded-lg" style={{
                                            maxHeight: '100px',
                                        }}/>
                                    </div>
                                )}
                                <div>
                                    <label htmlFor="file-upload"
                                           className="block text-sm font-medium text-gray-700 dark:text-gray-200">Image</label>
                                    <input id="file-upload" type="file" ref={inputImageRef} name="image"
                                           onChange={handleInputChange} className="hidden" accept="image/*" required/>
                                    <label htmlFor="file-upload"
                                           className="mt-4 block w-full border bg-white border-gray-400 rounded-md shadow-sm text-sm p-2.5 dark:border-gray-600 dark:bg-gray-700 dark:text-white cursor-pointer hover:bg-gray-200 dark:hover:bg-gray-800">
                                        <div className="flex items-center justify-center">
                                            <svg className="w-4 h-4 mr-2" fill="currentColor"
                                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                                <path
                                                    d="M16.88 2.88A3 3 0 0014.12.88L10 5H4a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V4.88a3 3 0 00-.12-.12zM14 7l-4-4-1 1 4 4 1-1zm-3 4a1 1 0 11-2 0 1 1 0 012 0zm-1 5a4 4 0 100-8 4 4 0 000 8z"></path>
                                            </svg>
                                            Choose File or Drag on here
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div>
                                <label
                                    className="block text-sm font-medium text-gray-700 dark:text-gray-200">Name</label>
                                <input type="text" name="name" value={formData.name} onChange={handleInputChange}
                                       className="mt-1 block w-full border border-gray-400 rounded-md shadow-sm text-sm p-2.5 dark:border-gray-600 dark:bg-gray-700 dark:text-white"/>
                            </div>
                            <div>
                                <label
                                    className="block text-sm font-medium text-gray-700 dark:text-gray-200">Symbol</label>
                                <input type="text" name="symbol" value={formData.symbol} onChange={handleInputChange}
                                       className="mt-1 block w-full border border-gray-400 rounded-md shadow-sm text-sm p-2.5 dark:border-gray-600 dark:bg-gray-700 dark:text-white"/>
                            </div>
                            {/*<div>*/}
                            {/*    <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Max Supply</label>*/}
                            {/*    <input type="number" name="maxSupply" value={formData.maxSupply} onChange={handleInputChange} min="0" required className="mt-1 block w-full border border-gray-400 rounded-md shadow-sm text-sm p-2.5 dark:border-gray-600 dark:bg-gray-700 dark:text-white" />*/}
                            {/*</div>*/}
                            <div>
                                <label className="block text-sm font-medium text-gray-700 dark:text-gray-200">Addresses
                                    ({formData.addresses.length})</label>
                                <textarea
                                    name="addresses"
                                    onChange={handleTextareaChange}
                                    value={formData.addresses.join(" ")}
                                    className="mt-1 block w-full border border-gray-400 rounded-md shadow-sm text-sm p-2.5 dark:border-gray-600 dark:bg-gray-700 dark:text-white"
                                />
                                {errorMessage && <p className="mt-1 text-xs text-red-500">{errorMessage}</p>}
                            </div>
                            <button type="submit" disabled={loading}
                                    className={`px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 ${loading ? 'animate-pulse' : ''}`}>
                                {loading ? loadingText : 'Generate NFT'}
                            </button>
                        </form>
                    </div>
                )}


                <div className="mt-10" key={key}>
                    <nav className="flex flex-col sm:flex-row">
                        <button className={"tablink sm:mr-4 mb-2 sm:mb-0 bg-gray-200 py-2 px-4 rounded "+(selectedTab === "nftList" ? "bg-blue-500 text-white" : "text-gray-700")}
                                onClick={()=>setSelectedTab("nftList")}>NFT List
                        </button>
                        <button className={"tablink bg-gray-200 py-2 px-4 rounded "+ (selectedTab === "txList" ? "bg-blue-500 text-white" : "text-gray-700")}
                                onClick={()=>setSelectedTab("txList")}>Transaction History
                        </button>
                    </nav>

                    {selectedTab === 'nftList' && (
                        <NftList/>
                    )}
                    {selectedTab === 'txList' && (
                        <TransactionList/>
                    )}
                </div>
            </section>
            <Footer/>
        </>
    )
}